import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { Divider } from '../../components/dividers';
import { trackWebEvent } from '../../helpers/analytics';
import {
  getSignInWithGoogleRedirectURL,
  sendSignInLinkToEmail,
  upsertState,
} from '../../helpers/authentication';
import { isEmailValid } from '../../helpers/utils';

export const SignInBlock: React.FC<{
  oauthState: string;
  where: string;
  setIsSignInInProgress: (isIt: boolean) => void;
}> = ({ oauthState, where, setIsSignInInProgress }) => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [isSendingEmailLink, setIsSendingEmailLink] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailUsedForGoogleSignIn, setIsEmailUsedForGoogleSignIn] =
    useState(false);
  const [isGoogleSignInRequiredByTeam, setIsGoogleSignInRequiredByTeam] =
    useState(false);

  const continueWithGoogle = (
    <Button
      variant="neutral-primary"
      size="large"
      fullWidth
      href={getSignInWithGoogleRedirectURL(oauthState, [
        'https://www.googleapis.com/auth/calendar.events.readonly',
      ])}
      onClick={() => {
        if (isSendingEmailLink) return false;
        trackWebEvent('Clicked Continue with Google', { where });
      }}
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 48 48"
        >
          <defs>
            <path
              id="a"
              d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
            />
          </defs>
          <clipPath id="b">
            <use xlinkHref="#a" overflow="visible" />
          </clipPath>
          <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
          <path
            clipPath="url(#b)"
            fill="#EA4335"
            d="M0 11l17 13 7-6.1L48 14V0H0z"
          />
          <path
            clipPath="url(#b)"
            fill="#34A853"
            d="M0 37l30-23 7.9 1L48 0v48H0z"
          />
          <path
            clipPath="url(#b)"
            fill="#4285F4"
            d="M48 48L17 24l-4-3 35-10z"
          />
        </svg>
      }
    >
      Continue with Google
    </Button>
  );

  if (isEmailUsedForGoogleSignIn) {
    return (
      <div className="flex flex-col items-center gap-4">
        <svg
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="47.5517" cy="47.9647" r="47.5517" fill="#4F46E5" />
          <path
            d="M42.607 31.5745C42.607 30.5998 42.2138 29.6651 41.5139 28.9759C40.814 28.2867 39.8647 27.8995 38.8749 27.8995C37.8851 27.8995 36.9358 28.2867 36.2359 28.9759C35.536 29.6651 35.1428 30.5998 35.1428 31.5745V49.9495L31.4858 46.348C30.7532 45.6274 29.797 45.1685 28.7693 45.0442C27.7415 44.9199 26.701 45.1374 25.813 45.662C24.9794 46.1542 24.3737 46.9471 24.1246 47.8723C23.8756 48.7974 24.0029 49.7818 24.4794 50.6159C29.1545 58.7989 31.8789 63.476 32.6552 64.6495C32.8171 64.8949 32.9797 65.1399 33.1429 65.3845C34.5026 67.4177 36.3539 69.0865 38.5305 70.2411C40.707 71.3957 43.1407 71.9999 45.613 71.9995H45.0955H50.0716C54.0309 71.9995 57.828 70.4508 60.6276 67.694C63.4272 64.9372 65 61.1982 65 57.2995V36.4745C65 35.4998 64.6068 34.5651 63.9069 33.8759C63.207 33.1867 62.2577 32.7995 61.2679 32.7995C60.2781 32.7995 59.3288 33.1867 58.6289 33.8759C57.929 34.5651 57.5358 35.4998 57.5358 36.4745M42.607 31.5745V47.4995M42.607 31.5745V26.6745C42.607 26.1919 42.7035 25.714 42.8911 25.2682C43.0786 24.8223 43.3535 24.4171 43.7001 24.0759C44.0467 23.7346 44.4581 23.4639 44.9109 23.2793C45.3637 23.0946 45.849 22.9995 46.3391 22.9995C46.8292 22.9995 47.3145 23.0946 47.7673 23.2793C48.2201 23.4639 48.6315 23.7346 48.9781 24.0759C49.3246 24.4171 49.5995 24.8223 49.7871 25.2682C49.9746 25.714 50.0712 26.1919 50.0712 26.6745V47.4995M50.0712 31.5745C50.0712 30.5998 50.4644 29.6651 51.1643 28.9759C51.8642 28.2867 52.8135 27.8995 53.8033 27.8995C54.7931 27.8995 55.7424 28.2867 56.4423 28.9759C57.1422 29.6651 57.5354 30.5998 57.5354 31.5745V47.4995"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div className="font-semibold text-xl">Hold on a moment!</div>

        {isGoogleSignInRequiredByTeam ? (
          <div>
            Your team requires you to use your Google account to sign in.
          </div>
        ) : (
          <div>
            You’ve already signed up to <b>Tactiq.io</b> using your Google
            account.
          </div>
        )}

        <div>Please sign in using the Google sign in link below.</div>

        {continueWithGoogle}

        <Button
          variant="soft"
          color="primary"
          size="large"
          fullWidth
          onClick={() => {
            setCurrentEmail('');
            setIsSendingEmailLink(false);
            setIsEmailSent(false);
            setIsEmailUsedForGoogleSignIn(false);
            setIsGoogleSignInRequiredByTeam(false);
            setIsSignInInProgress(false);

            trackWebEvent('Clicked Cancel when asked to log in with Google', {
              where,
            });
          }}
        >
          Cancel
        </Button>
      </div>
    );
  }

  if (isEmailSent) {
    return (
      <div className="flex flex-col gap-4">
        <svg
          width="134"
          height="104"
          viewBox="0 0 134 104"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M109.513 11.8386H126.804M118.159 20.4844V3.19287M113.836 7.51575L122.482 16.1615M113.836 16.1615L122.482 7.51575"
            stroke="#4F46E5"
            strokeWidth="1.72915"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1301 89.6503H28.0988M21.6144 96.1346V83.166M18.3723 86.4082L24.8566 92.8925M18.3723 92.8925L24.8566 86.4082"
            stroke="#4F46E5"
            strokeWidth="1.72915"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="72.048" cy="52.1857" r="47.5517" fill="#4F46E5" />
          <path
            d="M49.7134 41.3781L73.4892 57.2287L97.265 41.3781M49.7134 41.3781L73.4892 25.5276L97.265 41.3781M49.7134 41.3781V67.7957M97.265 41.3781V67.7957M97.265 67.7957C97.265 69.197 96.7084 70.5409 95.7175 71.5317C94.7267 72.5226 93.3828 73.0792 91.9815 73.0792H54.9969C53.5956 73.0792 52.2517 72.5226 51.2609 71.5317C50.27 70.5409 49.7134 69.197 49.7134 67.7957M97.265 67.7957L81.4145 51.9452M49.7134 67.7957L65.5639 51.9452"
            stroke="white"
            strokeWidth="2.88192"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.4162 12.683C28.8758 12.1426 28.1428 11.8389 27.3784 11.8389C28.1428 11.8389 28.8758 11.5353 29.4162 10.9949C29.9567 10.4544 30.2603 9.72136 30.2603 8.95703C30.2603 9.72136 30.564 10.4544 31.1044 10.9949C31.6449 11.5353 32.3779 11.8389 33.1423 11.8389C32.3779 11.8389 31.6449 12.1426 31.1044 12.683C30.564 13.2235 30.2603 13.9565 30.2603 14.7209C30.2603 13.9565 29.9567 13.2235 29.4162 12.683Z"
            stroke="#FDE047"
            strokeWidth="2.16144"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M128.273 51.5703C127.733 51.0298 127 50.7262 126.235 50.7262C127 50.7262 127.733 50.4225 128.273 49.8821C128.814 49.3416 129.117 48.6086 129.117 47.8442C129.117 48.6086 129.421 49.3416 129.961 49.8821C130.502 50.4225 131.235 50.7262 131.999 50.7262C131.235 50.7262 130.502 51.0298 129.961 51.5703C129.421 52.1107 129.117 52.8437 129.117 53.6081C129.117 52.8437 128.814 52.1107 128.273 51.5703Z"
            stroke="#FDE047"
            strokeWidth="2.16144"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M105.874 99.0527C104.928 98.1069 103.646 97.5756 102.308 97.5756C103.646 97.5756 104.928 97.0442 105.874 96.0984C106.82 95.1526 107.351 93.8698 107.351 92.5322C107.351 93.8698 107.883 95.1526 108.829 96.0984C109.774 97.0442 111.057 97.5756 112.395 97.5756C111.057 97.5756 109.774 98.1069 108.829 99.0527C107.883 99.9986 107.351 101.281 107.351 102.619C107.351 101.281 106.82 99.9986 105.874 99.0527Z"
            stroke="#FDE047"
            strokeWidth="2.16144"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="129.677" cy="79.5636" r="4.32288" fill="#FDE047" />
          <circle cx="17.7181" cy="67.3157" r="2.16144" fill="#FDE047" />
          <circle cx="2.16144" cy="47.1419" r="2.16144" fill="#FDE047" />
          <circle cx="14.7032" cy="27.983" r="4.32288" fill="#FDE047" />
        </svg>

        <div className="font-semibold text-xl">An email is on its way!</div>

        <div>
          We just sent an email to <b>{currentEmail}</b>. You’ll find a magic
          link that will sign you into your account.
        </div>

        <div>
          The link expires in <b>24 hours</b>, so make sure you use it soon!
        </div>

        <Divider />

        <div>
          Didnt receive an email? Please check your Spam folder. You can try
          again in 1 minute.
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {isSendingEmailLink ? null : (
        <>
          {continueWithGoogle}

          <Divider label={'Or use your email'} />
        </>
      )}

      <TextInput
        type="email"
        placeholder="you@example.com"
        value={currentEmail}
        disabled={isSendingEmailLink}
        onChange={setCurrentEmail}
      />

      <Button
        variant="neutral-primary"
        size="large"
        fullWidth
        loading={isSendingEmailLink}
        onClick={async () => {
          const email = currentEmail.trim();

          if (!email) {
            enqueueSnackbar('Please enter your email', {
              variant: 'ERROR',
            });
            return;
          }

          if (!isEmailValid(email)) {
            enqueueSnackbar('Please enter a valid email', {
              variant: 'ERROR',
            });
            return;
          }

          trackWebEvent('Clicked Send a magic link', { where });

          setIsSendingEmailLink(true);
          setIsSignInInProgress(true);

          const state = await upsertState(
            { email, language: navigator.language },
            oauthState
          );
          try {
            const result = await sendSignInLinkToEmail(state);
            if (result.requireGoogleSignIn) {
              setIsEmailUsedForGoogleSignIn(true);
              setIsSendingEmailLink(false);
              setIsGoogleSignInRequiredByTeam(Boolean(result.team));
            } else {
              setIsEmailSent(true);
            }
          } catch (error) {
            setIsSendingEmailLink(false);
            setIsSignInInProgress(false);
            enqueueSnackbar('Could not send an email, please try again later', {
              variant: 'ERROR',
            });
          }
        }}
        startIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M15 6.93018L18 9.93018M6 21.9302L21 6.93018L18 3.93018L3 18.9302L6 21.9302ZM9 3.93018C9 4.46061 9.21071 4.96932 9.58579 5.34439C9.96086 5.71946 10.4696 5.93018 11 5.93018C10.4696 5.93018 9.96086 6.14089 9.58579 6.51596C9.21071 6.89103 9 7.39974 9 7.93018C9 7.39974 8.78929 6.89103 8.41421 6.51596C8.03914 6.14089 7.53043 5.93018 7 5.93018C7.53043 5.93018 8.03914 5.71946 8.41421 5.34439C8.78929 4.96932 9 4.46061 9 3.93018ZM19 13.9302C19 14.4606 19.2107 14.9693 19.5858 15.3444C19.9609 15.7195 20.4696 15.9302 21 15.9302C20.4696 15.9302 19.9609 16.1409 19.5858 16.516C19.2107 16.891 19 17.3997 19 17.9302C19 17.3997 18.7893 16.891 18.4142 16.516C18.0391 16.1409 17.5304 15.9302 17 15.9302C17.5304 15.9302 18.0391 15.7195 18.4142 15.3444C18.7893 14.9693 19 14.4606 19 13.9302Z"
              stroke="#6366F1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        Send a magic link
      </Button>
    </div>
  );
};
